import { playNowLayoutId } from '@app/play-now/routes/play-now-layout-id';
import { loader } from '@app/play-now/routes/PlayApp.remix-route';
import { SerializeFrom } from '@remix-run/node';
import { MetaArgs, MetaDescriptor } from '@remix-run/react';

export type ClientMetaFunction<T> = (args: MetaArgs & { data: SerializeFrom<T> }) => MetaDescriptor[];

export const mergeMeta = ({ data: { meta: childMeta }, matches }: { data: { meta?: MetaDescriptor[] }; matches: MetaArgs['matches'] }) => {
  const parentMeta = matches.find((match) => match.id === playNowLayoutId)?.meta ?? [];
  if (!childMeta) return parentMeta;

  const childHasTitle = childMeta.some((descriptor) => 'title' in descriptor);
  const childNames = collectNames(childMeta);
  const childProperties = collectProperties(childMeta);

  const filteredParentMeta = parentMeta.filter((descriptor) => {
    if ('title' in descriptor && childHasTitle) {
      return false;
    }
    if ('name' in descriptor && typeof descriptor.name === 'string') {
      return !childNames.includes(descriptor.name);
    }
    if ('property' in descriptor && typeof descriptor.property === 'string') {
      return !childProperties.includes(descriptor.property);
    }
    return true;
  });

  return [...childMeta, ...filteredParentMeta];
};

export const getLayoutData = (matches: MetaArgs['matches']) =>
  matches.find((match) => match.id === playNowLayoutId)?.data as SerializeFrom<typeof loader>;

const collectNames = (descriptors: MetaDescriptor[]): string[] =>
  descriptors.filter((desc): desc is { name: string } => 'name' in desc && typeof desc.name === 'string').map((desc) => desc.name);

const collectProperties = (descriptors: MetaDescriptor[]): string[] =>
  descriptors
    .filter((desc): desc is { property: string } => 'property' in desc && typeof desc.property === 'string')
    .map((desc) => desc.property);
